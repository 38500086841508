/** @file Find components on the page and initialize them.
 */
import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';

import { UserProfileShort } from 'Components/form/userProfileShort/clientlib/js/userProfileShort.class.js';
import { UserProfile } from 'Components/form/userProfile/clientlib/js/userProfile.class.js';
import { MarketoSubscription } from 'Components/form/marketoSubscription/clientlib/js/marketoSubscription.class.js';
import { PasswordVerification } from 'Components/form/password/clientlib/js/passwordVerification.class.js';
import { Lounges } from 'Components/lounges/clientlib/js/lounges.class.js';
import { OmsOrderHistory } from 'Components/omsOrderHistory/clientlib/js/omsOrderHistory.class.js';
import { OmsOrderHistoryDetails } from 'Components/omsOrderHistoryDetails/clientlib/js/omsOrderHistoryDetails.class.js';
const registerComponent = AEM.registerComponent;


/*
**************************************
*********** Dynamic Imports ***********
**************************************
*/


/*
**************************************
*********** Static Imports ***********
**************************************
*/
/**
 * Initialize User Profile Short component.
 */
registerComponent('[data-component="amd-userprofileshort"]', UserProfileShort);

/**
 * Initialize pw verification component.
 */
registerComponent('[data-component="password-verification"]', PasswordVerification);

/**
 * Initialize lounges component.
 */
registerComponent('[data-component="profile-lounges"]', Lounges);


/**
 * Initialize OmsOrderHistory.
 */
registerComponent('[data-component="cmp-omsorderhistory"]', OmsOrderHistory);

/**
 * Initialize OmsOrderHistoryDetails.
 */
registerComponent('[data-component="cmp-omsorderhistorydetails"]', OmsOrderHistoryDetails);

/**
 * Initialize User Profile component.
 */
registerComponent('[data-component="amd-userprofile"]', UserProfile);

/**
 * Initialize Marketo Subscription Center component.
 */
registerComponent('[data-component="marketo-subscription"]', MarketoSubscription);

