import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import UserAccountFetch from 'base/js/templates/general/userAccountFetch';

class UserProfileShort extends AEM.Component {
    init() {
        this.getData();
    }

    getData() {
        UserAccountFetch.profileFetch('/bin/protected/account/profile', {
            cache: 'no-store'
        })
            .then(res => res.json())
            .then(response => {
                this.populateData(response);
            })
            .catch(err => {
                console.log(err);
                console.log('AJAX Error');
            });
    }

    populateData(results) {
        this.element.querySelector('input[name="Email"]').value = results.email;
        this.element.querySelector('input[name="First_Name"]').value = results.firstName;
        this.element.querySelector('input[name="Last_Name"]').value = results.lastName;
    }
}

export { UserProfileShort };
